ACC.track = {
  prevCarousel: 1,
  pushToDataLayer: function (data) {
    // Separate function to aid in debugging
    if (typeof dataLayer != 'undefined') {
      dataLayer.push(data);
    }
  },  

  /**
   * Single tracking event used to send off to our analytics platform.
   * Since this is using GA, it is sending off a specifically shaped object
   * according to their api. You can find an example @/mixins/tracking/track-checkout.js
   * @param data <Object> 
   */
  trackEvent(data) {

    // clear out previous push
    ACC.track.pushToDataLayer({ecommerce: null});
    // Push values to GA
    ACC.track.pushToDataLayer(data);
  },

  //store finder
  trackStore: function (name) {
    if (trackingEnabled) {
      ACC.track.pushToDataLayer({
        Label: name,
        Action: 'Clicked CTA',
        Category: 'Find A Store CTA',
        event: 'find-a-store-cta',
      });

      if (doLogging) {
        console.log('Select A Store');
        console.log('Store : ' + name);
      }
    }
  },

  //store finder
  trackDirections: function (directions) {
    if (trackingEnabled) {
      ACC.track.pushToDataLayer({
        Label: directions,
        Action: 'Directions Chosen',
        Category: 'Retailer Get Directions',
        event: 'get-directions',
      });

      if (doLogging) {
        console.log('Retailer Get Directions');
        console.log('Directions : ' + directions);
      }
    }
  },

  trackFindStores: function () {
    if (trackingEnabled) {
      ACC.track.pushToDataLayer({
        Action: 'Clicked CTA',
        Category: 'Find A Store CTA',
        event: 'find-a-store-cta',
      });
    }
  },

  //facet de-selection / selection
  checkFacet: function (facet) {
    if (trackingEnabled) {
      var facetNameAndValue = $(facet).attr('data-gtm');

      if ($(facet).prop('checked')) {
        ACC.track.pushToDataLayer({
          Label: facetNameAndValue,
          Action: 'Selection',
          Category: 'Product Facet Interaction',
          event: 'facet-interaction',
        });

        if (doLogging) {
          console.log('Facet Selection');
          console.log('Facet : ' + facetNameAndValue);
        }
      } else {
        ACC.track.pushToDataLayer({
          Label: facetNameAndValue,
          Action: 'Deselection',
          Category: 'Product Facet Interaction',
          event: 'facet-interaction',
        });

        if (doLogging) {
          console.log('Facet Deselection');
          console.log('Facet : ' + facetNameAndValue);
        }
      }
    }
  },

  //facet de-selection (clear all)
  checkFacetClearAll: function () {
    if (trackingEnabled) {
      $('input[type=checkbox]:checked').each(function () {
        var facetNameAndValue = $(this).attr('id');

        ACC.track.pushToDataLayer({
          Label: facetNameAndValue,
          Action: 'Deselection',
          Category: 'Product Facet Interaction',
          event: 'facet-interaction',
        });

        if (doLogging) {
          console.log('Facet Deselection');
          console.log('Facet : ' + facetNameAndValue);
        }
      });
    }
  },
  checkGA: function () {
    window.ga =
      window.ga ||
      function () {
        (ga.q = ga.q || []).push(arguments);
      };
    ga.l = +new Date();
  },
  trackDimensions: function () {
    ACC.track.checkGA();
    $('[data-ga-dimension]').each(function () {
      var dimension = $(this).data('ga-dimension');
      var dimensionValue = $(this).data('ga-dimension-value');
      ga('set', dimension, dimensionValue);
    });
  },
  //facet de-selection (clear)
  checkFacetClear: function (groupWrap) {
    if (trackingEnabled) {
      groupWrap.find(':checked').each(function () {
        var facetNameAndValue = $(this).attr('id');

        ACC.track.pushToDataLayer({
          Label: facetNameAndValue,
          Action: 'Deselection',
          Category: 'Product Facet Interaction',
          event: 'facet-interaction',
        });

        if (doLogging) {
          console.log('Facet Deselection');
          console.log('Facet : ' + facetNameAndValue);
        }
      });
    }
  },

  //facet show all
  checkFacetShowAll: function (facet) {
    if (trackingEnabled) {
      var facetName = $(facet).attr('data-facet-name');

      ACC.track.pushToDataLayer({
        Label: facetName,
        Action: 'See More',
        Category: 'Product Facet Interaction',
        event: 'facet-interaction',
      });

      if (doLogging) {
        console.log('Facet Show All');
        console.log('Facet : ' + facetName);
      }
    }
  },

  //preferredRetailer
  trackPreferredRetailer: function (name) {
    if (trackingEnabled) {
      ACC.track.pushToDataLayer({
        Label: name,
        Action: 'Store Chosen',
        Category: 'Preferred Store',
        event: 'preferred-store',
      });

      if (doLogging) {
        console.log('Preferred Store');
        console.log('Store : ' + name);
      }
    }
  },

  //store finder sorting
  trackStoreFinderSort: function (sortOption) {
    if (trackingEnabled) {
      ACC.track.pushToDataLayer({
        Action: sortOption,
        Category: 'Retailer Sort By',
        event: 'retailer-sort-by',
      });

      if (doLogging) {
        console.log('Retailer Sort By');
        console.log('SortBy : ' + sortOption);
      }
    }
  },

  //bound on document.ready
  //Track Contact US submit --- NOT WORKING ----
  checkContactUs: function () {
    if ($('.pageLabel--contactUs').length) {
      $('#contactUsIFrame')
        .contents()
        .find('form')
        .submit(function (event) {
          // This is not working
          // As iFrame source is on another domain can't get a handle on the content within it
        });
    }
  },

  //bound on document.ready
  checkStoreFinder: function () {
    if (trackingEnabled) {
      //store finder
      if ($('.template-pages-storeFinder-storeFinderSearchPage').length) {
        if ($('#numOfResults').length) {
          var numOfResults = $('#numOfResults').html();
          var query = $('#query').html();
          var url = window.location.href;
          var initLoad = url.indexOf('sort=') > -1 || url.indexOf('distance=') > -1 ? false : true;

          if (initLoad) {
            if (parseInt(numOfResults) > 0) {
              ACC.track.pushToDataLayer({
                Label: query,
                Action: 'Results Shown',
                Category: 'Store Finder',
                event: 'store-finder',
              });
            } else {
              ACC.track.pushToDataLayer({
                Label: query,
                Action: 'No Results Shown',
                Category: 'Store Finder',
                event: 'store-finder',
              });
            }

            if (doLogging) {
              console.log('Store finder');
              console.log('No Of Results : ' + numOfResults);
              console.log('Query : ' + query);
            }
          }
        }
      }
    }
  },

  //Track product impressions on PDP
  trackProductReferences: function (sku, productList) {
    if (trackingEnabled) {
      if (doLogging) {
        console.log('sku is ' + sku);
        console.log('product list is ' + productList);
      }
      var requestUrl = $('#gtmURL').html() + '/productsForGTM?sku=' + sku + '&productList=' + productList;
      $.ajax({
        type: 'GET',
        url: requestUrl,
        dataType: 'json',
        success: function (data) {
          ACC.track.pushToDataLayer(data);

          if (doLogging) {
            console.log('PDP Product Impressions');
            console.log(data);
          }
        },
        error: function (xht, textStatus, ex) {
          console.log('Ajax tracking of PDP Product References failed : ' + textStatus + '[' + ex + ']');
        },
      });
    }
  },

  trackFeatureInteraction: function (action, value) {
    if (trackingEnabled) {
      ACC.track.pushToDataLayer({
        Label: value,
        Action: action,
        Category: 'Feature Interaction',
        event: 'feature-interaction',
      });

      if (doLogging) {
        console.log('Feature Interaction');
        console.log('Action : ' + action);
        console.log('Value : ' + value);
      }
    }
  },

  //bound on document.ready
  //check for carousels rendered on page
  checkForCarousels: function () {
    if (trackingEnabled) {
      var heroCarousel = $('[data-carousel-hero]');
      if (heroCarousel.length) {
        ACC.track.trackCarouselHit('hero');
      }

      var carousel = $('[data-pagination-display]');
      if (carousel.length) {
        $(carousel).each(function () {
          var id = $(this).attr('id').replace('-pagination', '');
          if (id.indexOf('-') != -1) {
            var carouselType = id.substring(id.indexOf('-') + 1);
            ACC.track.trackCarouselHit(carouselType);
          }
        });
      }
    }
  },
  trackFromAttrs: function (target) {
    if (trackingEnabled) {
      var $this = $(target);
      var gaCategory = $this.data('ga-category');
      var gaEvent = $this.data('ga-event');
      var gaAction = $this.data('ga-action');
      var gaLabel = $this.data('ga-label');
      var data = {};
      if (gaCategory) {
        data['Category'] = gaCategory;
      }
      if (gaEvent) {
        data['event'] = gaEvent;
      } else {
        return;
      }
      if (gaAction) {
        data['Action'] = gaAction;
      }
      if (gaLabel) {
        data['Label'] = gaLabel;
      }
      ACC.track.pushToDataLayer(data);
    }
  },
  trackCarouselHit: function (type) {
    if (trackingEnabled) {
      ACC.track.pushToDataLayer({
        event: 'carousel-type',
        'carousel-type-value': type,
      });

      if (doLogging) {
        console.log('carousel-type : ' + type);
      }
    }
  },

  //bound on document.ready
  bindEventHandlers: function () {
    if (trackingEnabled) {
      $(document).delegate('.owl-dot', 'click', function (event) {
        var n = $(this).index();
        var div = $('.hero-carousel__slide').eq(n);
        ACC.track.trackFromAttrs(div);
      });

      $(document).delegate('.feature-link', 'click', function (event) {
        var featureLevel = $(this).data('featureLevel');
        var featureTitle = $(this).data('featureTitle');
        var trackData = {
          event: 'feature-link',
          Category: featureLevel + ' Product Feature Link',
          Action: 'clicked',
          Label: featureTitle,
        };
        ACC.track.pushToDataLayer(trackData);
      });

      $(document).delegate('.editorial-small__content a', 'click', function (event) {
        var title = $(this).siblings('h4').html();
        if (typeof dataLayer != 'undefined') {
          dataLayer.push({
            Label: title,
            Action: 'clicked',
            Category: 'Grand Category Interaction',
            event: 'grand-category-interaction',
          });
        }
      });

      $(document).delegate('.swatch__radio', 'click', function () {
        const color = $(this).attr('data-color');
        ACC.track.trackSwatch(color);
      });

      //track store finder feature filters
      $(document).delegate('.store-refinements__search-button', 'click', function (event) {
        //get all checked store features
        var checkedFilters = $('li[class="facet-group__list-item"]').find(':checked');

        $(checkedFilters).each(function () {
          var featureName = $(this).attr('id');
          var featureValue = $("label[for='" + featureName + "']")
            .text()
            .trim();

          ACC.track.pushToDataLayer({
            Label: featureValue,
            Action: 'Filter or Facet Clicked',
            Category: 'Retailer Filter Interaction',
            event: 'facet-filter-interaction',
          });

          if (doLogging) {
            console.log('Retailer Filter Interaction');
            console.log('Filter : ' + featureValue);
          }
        });
      });

      //track country selector
      $(document).delegate('.country__link', 'click', function (event) {
        if ($('.template-pages-misc-countrySelectorPage').length) {
          var countryLang = $(this).attr('data-source');
          ACC.track.pushToDataLayer({
            Label: countryLang,
            Action: 'Country Chosen',
            Category: 'Global Country Selector',
            event: 'global-country-selector',
          });

          if (doLogging) {
            console.log('Country Changed');
            console.log('countryLang : ' + countryLang);
          }
        }
      });

      $(document).delegate('.owl-next.pagination__button.arrow-right-icon--weak', 'click', function () {
        if ($('.template-pages--layout-productFamilyLayout').length) {
          ACC.track.pushToDataLayer({
            Event: 'carousel-interaction',
            Category: 'Carousel Interaction',
            Action: 'R',
            Label: 'Product Family Page',
          });
        }
      });

      $(document).delegate('.owl-prev.pagination__button.arrow-right-icon--weak', 'click', function () {
        if ($('.template-pages--layout-productFamilyLayout').length) {
          ACC.track.pushToDataLayer({
            Event: 'carousel-interaction',
            Category: 'Carousel Interaction',
            Action: 'L',
            Label: 'Product Family Page',
          });
        }
      });
      $(document).delegate('.owl-next.pagination__button.arrow-left-icon--weak', 'click', function () {
        if ($('.template-pages--layout-productFamilyLayout').length) {
          ACC.track.pushToDataLayer({
            Event: 'carousel-interaction',
            Category: 'Carousel Interaction',
            Action: 'L',
            Label: 'Product Family Page',
          });
        }
      });

      //track social interaction
      $(document).delegate('.social-icon', 'click', function (event) {
        var socialNetwork = $(this).attr('title');
        var socialTarget = $(this).attr('href');

        var socialAction = 'Share';
        if (socialNetwork.indexOf('facebook') > -1) {
          socialNetwork = 'Facebook';
        } else if (socialNetwork.indexOf('twitter') > -1) {
          socialAction = 'Tweet';
          socialNetwork = 'Twitter';
        } else if (socialNetwork.indexOf('instagram') > -1) {
          socialNetwork = 'Instagram';
        } else if (socialNetwork.indexOf('youtube') > -1) {
          socialNetwork = 'YouTube';
        } else if (socialNetwork.indexOf('linkedin') > -1) {
          socialNetwork = 'LinkedIn';
        } else if (socialNetwork.indexOf('kakao') > -1) {
          socialNetwork = 'Kakao';
        }

        ACC.track.pushToDataLayer({
          event: 'offsite-links',
          Category: 'Offsite Links',
          Action: socialAction,
          Label: socialTarget,
        });

        if (doLogging) {
          console.log('Social Interaction');
          console.log('Network : ' + socialNetwork);
          console.log('Action : ' + socialAction);
          console.log('Target : ' + socialTarget);
        }
      });

      //menu click
      $(document).delegate(
        'div.primary-navigation__list-item a.link-weak, .primary-navigation__secondary-title.collapse-icon.expand-icon-after, .primary-navigation__title',
        'click',
        function (event) {
          var menuCategory = $(this).text();

          var selectedMenuItem = $(this).closest('li[class="primary-navigation__item"]').find('span:first').text();

          ACC.track.pushToDataLayer({
            Label: menuCategory,
            Action: selectedMenuItem,
            Category: 'Menu Interaction',
            event: 'menu-interaction',
          });

          if (doLogging) {
            console.log('Menu Click');
            console.log('Top Level item : ' + selectedMenuItem);
            console.log('menu item : ' + menuCategory);
          }
        }
      );
      //carousel pagination / interaction - prev
      $(document).delegate('[data-pagination-previous]', 'click', function (event) {
        var id = $(this).attr('id').replace('-prev', '');
        if (id.indexOf('-') != -1) {
          var carouselName = id.substring(0, id.indexOf('-'));
          var carouselType = id.substring(id.indexOf('-') + 1);
          var pagination = $('#' + id + '-pagination')
            .text()
            .trim();
          var pageNum = pagination.substring(0, pagination.indexOf(' '));

          ACC.track.pushToDataLayer({
            Label: pageNum,
            Action: carouselName,
            Category: 'Carousel Pagination',
            event: 'carousel-pagination',
          });

          if (doLogging) {
            console.log('Carousel Pagination');
            console.log('Carousel : ' + carouselName);
            console.log('Page : ' + pageNum);
          }

          ACC.track.pushToDataLayer({
            Label: carouselType,
            Action: 'Left',
            Category: 'Carousel Interaction',
            event: 'carousel-interaction',
          });

          if (doLogging) {
            console.log('Carousel Interaction');
            console.log('Type & Direction : ' + carouselType + '- Left');
          }
        }
      });

      $(document).delegate('[data-ga-event]', 'click', function (event) {
        ACC.track.trackFromAttrs(this);
      });

      $(document).delegate('.button-primary', 'click', function (event) {
        if ($('.template-pages--productRegistration-newsletterPage').length) {
          ACC.track.pushToDataLayer({
            event: 'newsletter-signup',
            Category: 'Newsletter Signup',
            Action: 'Subscribed',
          });
        }
      });

      // Track links
      $(document).delegate('a', 'click', function (event) {
        var $this = $(this);
        if ($this.hasClass('social-icon')) {
          return;
        }
        var link = $this.attr('href');

        if (typeof link === 'undefined') {
          return;
        }
        var first1 = link.substr(0, 1);
        if (first1 == '#') {
          return;
        }
        if ($this.is('[data-video]')) {
          return;
        }
        if (link.substr(0, 10) == 'javascript') {
          return;
        }
        var first2 = link.substr(0, 2);
        var linkText = $this.text().trim();
        var suffix = '';
        var lastDot = link.lastIndexOf('.');
        if (lastDot >= 0) {
          suffix = link.substr(lastDot);
        }
        // Others?
        if (suffix == '.pdf' || suffix == '.xlsx' || suffix == '.mp4') {
          ACC.track.pushToDataLayer({
            Category: 'Download Collateral',
            Action: $('title').text().trim(),
            event: 'download-collateral',
            Label: linkText,
          });
          if (doLogging) {
            console.log('Download collateral - ' + link);
          }
          return;
        }

        if ((first1 == '/' && first2 != '//') || first1 == '?') {
          if (doLogging) {
            console.log('Internal link - no event');
          }
          return;
        } else {
          var id = $this.attr('id');
          ACC.track.pushToDataLayer({
            Category: 'Offsite Links',
            event: 'offsite-links',
            Action: linkText,
            Label: link,
          });
        }
      });

      //carousel pagination / interaction - next
      $(document).delegate('[data-pagination-next]', 'click', function (event) {
        var id = $(this).attr('id').replace('-next', '');
        if (id.indexOf('-') != -1) {
          var carouselName = id.substring(0, id.indexOf('-'));
          var carouselType = id.substring(id.indexOf('-') + 1);
          var pagination = $('#' + id + '-pagination')
            .text()
            .trim();
          var pageNum = pagination.substring(0, pagination.indexOf(' '));

          ACC.track.pushToDataLayer({
            Label: pageNum,
            Action: carouselName,
            Category: 'Carousel Pagination',
            event: 'carousel-pagination',
          });

          if (doLogging) {
            console.log('Carousel Pagination');
            console.log('Carousel : ' + carouselName);
            console.log('Page : ' + pageNum);
          }
          trackCarouselPage(carouselType, 'Right');
        }
      });

      var trackCarouselPage = function (carouselType, direction) {
        ACC.track.pushToDataLayer({
          Label: carouselType,
          Action: direction,
          Category: 'Carousel Interaction',
          event: 'carousel-interaction',
        });

        if (doLogging) {
          console.log('Carousel Interaction');
          console.log('Type & Direction : ' + carouselType + '- ' + direction);
        }
      };

      $(document).delegate('.hero-pagination-button-left', 'click', function () {
        var type = $(this).closest('section').attr('id');
        trackCarouselPage(type, 'Left');
      });

      $(document).delegate('.hero-pagination-button-right', 'click', function () {
        // if($(this).closest('#HomeHeroCarousel').length) {
        // 	return;
        // }
        var type = $(this).closest('section').attr('id');
        trackCarouselPage(type, 'Right');
      });
      var handleOwlButton = function ($this) {
        var current = Number($this.siblings('.owl-indicator').find('.owl-count').text().trim());
        if (current == ACC.track.prevCarousel) {
          return;
        }
        ACC.track.prevCarousel = current;
        var page = current;
        var name = $('[data-ga-carousel-name]').data('ga-carousel-name');
        if (name === undefined) {
          var $section = $this.closest('section');
          name = $section.data('ga-carousel-title');
          if (name === undefined) {
            name = $section.attr('id');
            if (name === undefined) {
              var parent = $this.closest('.owl-carousel');
              var child = parent.find('.owl-item.active');
              var img = child.find('img');
              var url = img.attr('src');
              var qIx = url.indexOf('?');
              name = url.substring(0, qIx);
              var lastSlash = name.lastIndexOf('/');
              name = name.substr(lastSlash + 1);
              name = name.replace(/_/g, ' ');
            }
          }
        }
        ACC.track.pushToDataLayer({
          event: 'carousel-pagination',
          Category: 'Carousel Pagination',
          Action: name,
          Label: page,
        });
      };

      $(document).delegate('.owl-next.pagination__button', 'click', function () {
        handleOwlButton($(this));
      });
      $(document).delegate('.owl-prev.pagination__button', 'click', function () {
        handleOwlButton($(this));
      });

      //PDP : track size click
      $(document).delegate('.buying-zone__size-select.form__select-holder select', 'change', function (event) {
        //get size

        var size = $.trim($('.buying-zone__size-select.form__select-holder select option:selected').text());

        ACC.track.pushToDataLayer({
          event: 'size-selection',
          Category: 'Size Selection',
          Action: 'In Stock',
          Label: $('.buying-zone__title.header-largish').text() + ' - ' + size,
        });

        if (doLogging) {
          console.log('size : ' + size);
        }
      });

      $('#storeFinderForm').submit(function () {
        var query = $('#storelocator-query').val();
        // skip over post codes, which should have a digit.
        if (query.length > 0 && query.length < 10 && /[0-9]/.test(query)) {
          return;
        }
      });

      $(document).delegate('#footerRetailerLink', 'click', function () {
        ACC.track.pushToDataLayer({
          event: 'find-a-retailer-cta',
          Category: 'Find A  Retailer CTA Interaction',
          Action: 'Footer',
        });
      });

      $(document).delegate('.hero-carousel__button', 'click', function (e) {
        ACC.track.pushToDataLayer({
          event: 'toggle-interaction',
          Category: 'Hero Image',
          Action: 'Click',
          Label: $(this).data('ga-label'),
        });
      });

      $(document).ready(function () {
        if (typeof MutationObserver === 'function') {
          var viewed = [];
          var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
              if (mutation.attributeName) {
                var $target = $(mutation.target);
                if ($target.hasClass('active') && !$target.data('ga-event-logged')) {
                  $target.attr('data-ga-event-logged', '1');
                  var $slide = $target.find('.hero-carousel__slide');
                  var index = $slide.data('index');
                  var alreadyViewed = viewed.indexOf(index) > -1;
                  viewed.push(index);

                  if ($slide.length && !alreadyViewed) {
                    ACC.track.pushToDataLayer({
                      event: 'toggle-interaction',
                      Category: 'Hero Image',
                      Action: 'Impression',
                      Label: $slide.data('ga-label'),
                    });
                  }
                }
              }
            });
          });
          $('#HomeHeroCarousel .owl-item').each(function () {
            observer.observe(this, {
              attributes: true,
            });
          });
        }
      });
    }
  },
  trackDistance: function (select) {
    var $select = $(select);
    var distance = $select.find('option:selected').text();
    if (distance !== undefined) {
      ACC.track.pushToDataLayer({
        event: 'retailer-distance',
        Category: 'Retailer Distance',
        Action: distance,
        Label: distance,
      });
    }
  },
  trackGender: function () {
    ACC.track.checkGA();
    var gender = $('[data-gender]');
    if (gender.length) {
      gender.each(function () {
        var $this = $(this);
        var dimensionValue = $this.data('gender');
        ga('set', 'dimension6', dimensionValue);
      });
    }
  },
  trackRetailerResult: function () {
    var url = window.location.href;
    var initLoad = url.indexOf('sort=') > -1 || url.indexOf('distance=') > -1 ? false : true;
    if (initLoad) {
      $('[data-ga-event-static]').each(function () {
        if (trackingEnabled) {
          $this = $(this);
          var gaCategory = $this.data('ga-category-static');
          var gaEvent = $this.data('ga-event-static');
          var gaAction = $this.data('ga-action-static');
          var gaLabel = $this.data('ga-label-static');
          var data = {};
          if (gaCategory) {
            data['Category'] = gaCategory;
          }
          if (gaEvent) {
            data['event'] = gaEvent;
          }
          if (gaAction) {
            data['Action'] = gaAction;
          }
          if (gaLabel) {
            data['Label'] = gaLabel;
          }
          ACC.track.pushToDataLayer(data);
        }
      });
    }
  },
  trackBrand: function () {
    var $brandName = $('[data-brand-name]');
    if ($brandName.length) {
      var dimensionValue = $brandName.data('brand-name');
      ga('set', 'dimension7', dimensionValue);
    }
  },
  trackMiniCart: function (link) {
    var count = $('#miniCartItemCount').text();
    ACC.track.pushToDataLayer({
      event: 'shopping-cart-view',
      Category: 'Shopping Cart Viewed',
      Action: 'clicked',
      Label: count,
    });
  },
  trackFirstCarouselImage: function () {
    var firstCarousel = $('[data-index="0"]:first');
    if (firstCarousel.length > 0) {
      var label = firstCarousel.data('ga-label');
      ACC.track.pushToDataLayer({
        event: 'toggle-interaction',
        Category: 'Hero Image CTR',
        Action: 'Impression',
        Label: label,
      });
    }
  },
};

$(document).ready(function () {
  if (trackingEnabled) {
    $.extend({
      getUrlVars: function () {
        var vars = [],
          hash;
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for (var i = 0; i < hashes.length; i++) {
          hash = hashes[i].split('=');
          vars.push(hash[0]);
          vars[hash[0]] = hash[1];
        }
        return vars;
      },
      getUrlVar: function (name) {
        return $.getUrlVars()[name];
      },
    });
    ACC.track.checkStoreFinder();
    //ACC.track.checkContactUs();
    ACC.track.bindEventHandlers();
    ACC.track.checkForCarousels();
    ACC.track.trackGender();
    ACC.track.trackDimensions();
    ACC.track.trackRetailerResult();
    ACC.track.trackBrand();
    ACC.track.trackFirstCarouselImage();
  }
});
